import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Img from 'gatsby-image';

import styles from './index.module.css';

const Image = ({
  className,
  imgData,
}) => {
  if (!imgData.localFile) {
    return null;
  }

  const {
    alt_text,
    caption,
    localFile,
  } = imgData;

  if (!localFile.childImageSharp) {
    return null;
  }

  return (
    <div className={ cx(styles.img, className) }>
      <Img
        alt={ alt_text }
        className={ styles.gatsbyImg }
        fluid={ localFile.childImageSharp.fluid }
      />
      { caption &&
        <p
          className={ styles.caption }
          dangerouslySetInnerHTML={ { __html: caption } }
        />
      }
    </div>
  );
};

Image.defaultProps = {
  imgData: {},
};

Image.propTypes = {
  className: PropTypes.string,
  imgData: PropTypes.shape({
    caption: PropTypes.string,
    alt_text: PropTypes.string,
    localFile: PropTypes.object.isRequired,
  }),
};

export default Image;
