import React from 'react';

import ShareBtns from './ShareBtns';

import ContextConsumer from '../../context/ContextConsumer';

const ShareBtnsWrapper = props => (
  <ContextConsumer>
    { context => (
      <ShareBtns
        { ...props }
        { ...context.state }
      />
    ) }
  </ContextConsumer>
);

export default ShareBtnsWrapper;
