import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import compose from 'recompose/compose';
import { CSSTransition } from 'react-transition-group';

import { HEADER_HEIGHT } from '../../../constants/header';
import ICONS from '../../../constants/icons';
import CONTENT_TYPES from '../../../constants/content-types';
import LS_KEYS from '../../../constants/local-storage';

import Icon from '../../Icon';
import ClipboardBtn from '../../ClipboardBtn';
import ContentList from '../../ContentList';
import CTABlurGate from '../../CTABlurGate';
import withScrollListener from '../../withScrollListener';
import withWindowListener from '../../withWindowListener';

import styles from './index.module.css';

class SubTopic extends Component {
  static propTypes = {
    className: PropTypes.string,
    content_subtopic: PropTypes.arrayOf(PropTypes.shape({
      content_type: PropTypes.string.isRequired, // TODO check for actual accepted types
    })),
    content_subtopic_title: PropTypes.string.isRequired,
    content_subtopic_slug: PropTypes.string.isRequired,
    content_subtopic_author: PropTypes.string,
    data: PropTypes.object.isRequired,
    hasSubTopicCTA: PropTypes.bool.isRequired,
    isContentGated: PropTypes.bool.isRequired,
    isInSubtopic: PropTypes.bool,
    isLarge: PropTypes.bool.isRequired,
    scroll: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
    viewport: PropTypes.string.isRequired,
  }

  state = {
    isAtBottom: false,
    isAtTop: false,
    isExpanded: false,
    isEntered: false,
    shareLink: '',
    subtopicsSeen: 0,
  }

  componentDidMount() {
    const { content_subtopic_slug } = this.props;

    const shareLink = `${ window.location.origin }/guide/${ content_subtopic_slug }`;

    let subtopicsSeen = window.localStorage.getItem(LS_KEYS.WFG_SUBTOPICS_SEEN);
    subtopicsSeen = subtopicsSeen ? parseInt(subtopicsSeen, 10) : 0;

    this.setState({
      shareLink,
      subtopicsSeen,
    });
  }

  componentDidUpdate(prevProps) {
    const { scroll } = this.props;
    const { isExpanded } = this.state;

    if (prevProps.scroll !== scroll && isExpanded) {
      this.handleScroll();
    }
  }

  render() {
    const {
      className,
      content_subtopic_author,
      content_subtopic_slug,
      data,
      hasSubTopicCTA,
      isContentGated,
      slug,
      theme,
    } = this.props;
    const {
      isExpanded,
      subtopicsSeen,
    } = this.state;

    const isCTAVisible = hasSubTopicCTA && isContentGated && subtopicsSeen > 2;

    const authorData = data.allWordpressWpUsers.edges.find(edge =>
      edge.node.slug === content_subtopic_author);

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.themeDark]: theme === 'dark',
      [styles.gated]: isCTAVisible,
      [styles.expanded]: isExpanded,
    }, className);

    return (
      <div className={ wrapperClasses }>
        <div className={ styles.inner } ref={ r => { this.innerEl = r; } }>
          { this.renderTriggerBtn() }

          { this.renderShareBtn() }

          { this.renderCloseBtn() }

          { this.renderContentList() }

        </div>

        { authorData && this.renderAuthor(authorData.node) }

        { isCTAVisible && isExpanded &&
          <CTABlurGate
            className={ styles.ctaBlurGate }
            isActive={ isExpanded }
            subtopic={ content_subtopic_slug }
            chapter={ slug }
          />
        }
      </div>
    );
  }

  renderTriggerBtn = () => {
    const {
      content_subtopic_slug,
      content_subtopic_title,
    } = this.props;

    return (
      <a
        className={ styles.triggerBtn }
        href={ `/guide/${ content_subtopic_slug }` }
        onClick={ this.handleTriggerClick }
      >
        <div className={ styles.triggerBtnInner }>
          <div className={ styles.triggerBtnTitle }>
            <Icon className={ styles.triggerBtnTooltip } icon={ ICONS.TOOLTIP } />
            <div
              className={ styles.triggerBtnTitleText }
              dangerouslySetInnerHTML={ { __html: content_subtopic_title } }
            />
          </div>
          <Icon className={ styles.triggerBtnArrow } icon={ ICONS.CHEVRON_RIGHT } />
        </div>
      </a>
    );
  }

  renderShareBtn = () => {
    const { isLarge } = this.props;
    const {
      isExpanded,
      shareLink,
    } = this.state;

    return (
      <CSSTransition
        in={ isExpanded }
        classNames='subtopicShareBtnTransition'
        timeout={ {
          enter: 400,
          exit: 0,
        } }
        unmountOnExit
      >
        <ClipboardBtn
          className={ styles.shareBtn }
          label='Share'
          shareLink={ shareLink }
          small
          successText={ isLarge ? 'Link copied!' : 'Copied!' }
        />
      </CSSTransition>
    );
  }

  renderCloseBtn = () => {
    const { isInSubtopic } = this.props;
    const {
      isAtBottom,
      isAtTop,
      isCTAVisible,
      isExpanded,
    } = this.state;

    const closeBtnClasses = cx({
      [styles.closeBtn]: true,
      [styles.bottom]: isAtBottom,
      [styles.top]: isAtTop,
    });

    return (
      <CSSTransition
        in={ !isCTAVisible && !isInSubtopic && isExpanded }
        classNames='subtopicCloseBtnTransition'
        timeout={ {
          enter: 400,
          exit: 0,
        } }
        unmountOnExit
      >
        <button
          className={ closeBtnClasses }
          type='button'
          onClick={ this.handleCloseBtnClick }
        >
          <div className={ styles.closeBtnInner }>
            <Icon className={ styles.closeBtnIcon } icon={ ICONS.CHEVRON_RIGHT } />
          </div>
        </button>
      </CSSTransition>
    );
  }

  renderContentList = () => {
    const {
      content_subtopic,
      slug,
    } = this.props;
    const {
      isExpanded,
      isEntered,
    } = this.state;

    const contentClasses = cx({
      [styles.content]: true,
      [styles.entered]: isEntered,
    });

    return (
      <CSSTransition
        in={ isExpanded }
        classNames='subtopicTransition'
        timeout={ {
          enter: 2000,
          exit: 0,
        } }
        unmountOnExit
        onEntered={ this.handleSubtopicContentEntered }
      >
        <ContentList
          className={ contentClasses }
          content={ content_subtopic }
          isInSubtopic
          slug={ slug }
          typeClassNames={ {
            [CONTENT_TYPES.IMAGE]: styles.subTopicImage,
            [CONTENT_TYPES.VIDEO_EMBED]: styles.subTopicVideoEmbed,
            [CONTENT_TYPES.SUB_TOPIC]: styles.subTopicSubTopic,
          } }
        />
      </CSSTransition>
    );
  }

  renderAuthor = ({ name, url, description }) => (
    <div className={ styles.author }>
      Contributed by:
      <span>{ name }</span>
      ,
      <a
        href={ url }
        className={ styles.authorLink }
        target='_blank'
        rel='noopener noreferrer'
      >
        { description }
      </a>
    </div>
  )

  handleTriggerClick = e => {
    const {
      content_subtopic_slug,
      isInSubtopic,
      scroll,
      slug,
    } = this.props;
    const {
      isExpanded,
      subtopicsSeen,
    } = this.state;

    e.preventDefault();

    this.setState({
      isExpanded: !isExpanded,
      isEntered: false,
      isAtBottom: false,
      isAtTop: false,
    });

    if (!isExpanded) {
      window.localStorage.setItem([LS_KEYS.WFG_SUBTOPICS_SEEN], subtopicsSeen + 1);

      window.analytics.track('wfg-subtopic-viewed', {
        chapter: slug,
        subtopic: content_subtopic_slug,
      });

      this.setState({
        subtopicsSeen: subtopicsSeen + 1,
      });

      this.handleScroll();
    }

    // Horrible hack to force scroll check and show/hide close button.
    if (isInSubtopic) {
      setTimeout(() => {
        window.scroll(0, scroll - 1);
      }, 10);
    }
  }

  handleCloseBtnClick = () => {
    const {
      content_subtopic_slug,
      scroll,
      viewport,
    } = this.props;

    const top = this.innerEl.getBoundingClientRect().top +
      scroll -
      HEADER_HEIGHT[viewport] -
      20; // to add some padding above the subtopic

    if (scroll > top) {
      window.scroll(0, top);
    }

    this.setState({
      isExpanded: false,
      isAtBottom: false,
      isAtTop: false,
    });

    const clickInfo = {
      client: 'wfg',
      page: content_subtopic_slug,
      position: 'bottom',
      title: 'close expandable subtopic',
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);
  }

  handleSubtopicContentEntered = () => {
    this.setState({
      isEntered: true,
    });
  }

  handleScroll = () => {
    const {
      isLarge,
      scroll,
      viewport,
    } = this.props;
    const {
      isAtBottom,
      isAtTop,
    } = this.state;

    const top = this.innerEl.getBoundingClientRect().top +
      scroll -
      HEADER_HEIGHT[viewport];
    const bottom = top + this.innerEl.offsetHeight + HEADER_HEIGHT[viewport];

    // The distance from the fixed button to the bottom of the container.
    const bottomMargin = isLarge ? 25 : 93;

    if (scroll <= top && !isAtTop) {
      this.setState({
        isAtTop: true,
      });
    } else if (scroll > top && isAtTop) {
      this.setState({
        isAtTop: false,
      });
    }

    if (scroll > bottom - window.innerHeight + bottomMargin && !isAtBottom) {
      this.setState({
        isAtBottom: true,
      });
    } else if (scroll <= bottom - window.innerHeight + bottomMargin && isAtBottom) {
      this.setState({
        isAtBottom: false,
      });
    }
  }
}

export default compose(
  withScrollListener,
  withWindowListener,
)(SubTopic);
