import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LS_KEYS from '../../constants/local-storage';
import ICONS from '../../constants/icons';

import Icon from '../Icon';

import styles from './index.module.css';

class EmailInput extends Component {
  static propTypes = {
    submitBtnLabel: PropTypes.string,
    onChangeContentGated: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitBtnLabel: 'Submit',
  }

  state = {
    emailValue: '',
    isInvalidEmail: false,
  }

  render() {
    const { submitBtnLabel } = this.props;
    const {
      emailValue,
      isInvalidEmail,
    } = this.state;

    return (
      <div className={ styles.wrapper }>
        <input
          className={ styles.input }
          onChange={ this.handleInputChange }
          onKeyPress={ this.handleKeyPress }
          placeholder='Email address'
          type='email'
          value={ emailValue }
        />
        <button
          className={ styles.submitBtn }
          type='button'
          onClick={ this.handleSubmit }
        >
          <div className={ styles.submitBtnInner } id='cta-blur-submit'>
            { submitBtnLabel }
          </div>
        </button>
        { isInvalidEmail &&
          <Icon className={ styles.invalidIcon } icon={ ICONS.CLOSE_BTN } />
        }
      </div>
    );
  }

  handleInputChange = evt => {
    const val = evt.target.value;

    this.setState({
      emailValue: val,
      isInvalidEmail: false,
    });
  }

  handleKeyPress = evt => {
    if (evt.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    const { emailValue } = this.state;

    const emailRegex = /.+@.+\.\w+/;

    if (emailRegex.test(emailValue)) {
      this.handleRemoveGate(emailValue);

      window.analytics.track('wfg-email-submitted', {
        wfg_email_submitted: emailValue,
        position: 'fullscreenCTA',
      });
    } else {
      this.setState({
        isInvalidEmail: true,
      });
    }
  }

  handleRemoveGate = email => {
    const { onChangeContentGated } = this.props;

    onChangeContentGated(false);
    window.localStorage.setItem([LS_KEYS.WFG_KNOWN_USER], true);

    if (email) {
      window.localStorage.setItem([LS_KEYS.WFG_EMAIL], email);
    }
  }
}

export default EmailInput;
