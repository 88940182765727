import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

class PrimaryNav extends Component {
  static propTypes = {
    activeChapter: PropTypes.string.isRequired,
    chapterScrollPct: PropTypes.number.isRequired,
    chapters: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        chapter_meta: PropTypes.shape({
          chapter_nav_title: PropTypes.string.isRequired,
        }),
      }),
    })).isRequired,
    isInHamburger: PropTypes.bool,
    onChangeScrollToChapter: PropTypes.func.isRequired,
    theme: PropTypes.string.isRequired,
  }

  render() {
    const {
      activeChapter,
      chapters,
      chapterScrollPct,
      isInHamburger,
      theme,
    } = this.props;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.themeDark]: isInHamburger ? theme !== 'dark' : theme === 'dark',
    });

    return (
      <div className={ wrapperClasses }>
        <ul className={ styles.chapterList }>
          { chapters.map((chapter, i) => {
            const activeChapterIndex = chapters.findIndex(chapterItem =>
              chapterItem.slug === activeChapter);
            const isActive = i === activeChapterIndex;

            const chapterProgressStyles = {
              height: 0,
            };

            if (isActive && i < chapters.length - 1) {
              chapterProgressStyles.height = `${ chapterScrollPct * 30 }px`;
            }

            const chapterItemClasses = cx({
              [styles.chapterItem]: true,
              [styles.previous]: i < activeChapterIndex,
              [styles.current]: isActive,
            });

            return (
              <li className={ chapterItemClasses } key={ chapter.slug }>
                <div className={ styles.chapterProgressLine } style={ chapterProgressStyles } />
                <button
                  className={ styles.chapterBtn }
                  type='button'
                  onClick={ () => this.handleChapterClick(chapter.slug) }
                >
                  <div className={ styles.chapter }>
                    <div className={ styles.chapterBullet } />
                    { chapter.title }
                  </div>
                </button>
              </li>
            );
          }) }
        </ul>
      </div>
    );
  }

  handleChapterClick = slug => {
    const {
      activeChapter,
      onChangeScrollToChapter,
    } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ activeChapter }`,
      position: 'middle left',
      title: `click primary nav: ${ slug }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    onChangeScrollToChapter(slug);
  }
}

export default PrimaryNav;
