import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import SubTopic from './SubTopic';

import ContextConsumer from '../../../context/ContextConsumer';

const SubTopicWrapper = props => (
  <StaticQuery
    query={ graphql`
      query {
        allWordpressWpUsers {
          edges {
            node {
              description
              name
              slug
              url
            }
          }
        }
      }
      ` }
    render={ data => (
      <ContextConsumer>
        { context => (
          <SubTopic
            { ...props }
            { ...context.state }
            data={ data }
          />
        ) }
      </ContextConsumer>
    ) }
  />
);

export default SubTopicWrapper;
