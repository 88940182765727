import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { navigate } from 'gatsby';

import HEADER_LINKS from '../../constants/header-links';
import ICONS from '../../constants/icons';
import LINKS from '../../constants/links';

import Icon from '../Icon';
import PrimaryNav from '../Nav/PrimaryNav';
import ThemeToggle from '../ThemeToggle';

import styles from './index.module.css';

class HamburgerMenu extends Component {
  static propTypes = {
    isGuide: PropTypes.bool,
    onCloseBtnClick: PropTypes.func.isRequired,
    theme: PropTypes.string.isRequired,
  }

  state = {
    pathName: '',
  }

  componentDidMount() {
    this.setState({
      pathName: window.location.pathname,
    });
  }

  render() {
    const {
      isGuide,
      onCloseBtnClick,
      theme,
    } = this.props;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.themeDark]: theme === 'dark',
    });

    return (
      <div className={ wrapperClasses }>
        <button
          className={ styles.overlay }
          type='button'
          onClick={ onCloseBtnClick }
        />
        <div className={ styles.menu }>
          <div className={ styles.btnWrapper }>
            <button
              className={ styles.closeBtn }
              type='button'
              onClick={ onCloseBtnClick }
            >
              <Icon className={ styles.closeIcon } icon={ ICONS.CLOSE_BTN } />
            </button>
          </div>

          { this.renderLinks() }

          { isGuide &&
            <div className={ styles.navWrapper }>
              <PrimaryNav isInHamburger />
            </div>
          }
          { isGuide &&
            <div className={ styles.toggleWrapper }>
              <ThemeToggle isInHamburger />
            </div>
          }
        </div>
      </div>
    );
  }

  renderLinks = () => {
    const { pathName } = this.state;

    return (
      <ul className={ styles.linkList }>
        { HEADER_LINKS.map(headerLink => {
          const linkClasses = cx({
            [styles.link]: true,
            [styles.active]: pathName === headerLink.link,
          });

          return (
            <li className={ styles.linkItem } key={ headerLink.link }>
              <button
                className={ linkClasses }
                onClick={ () => this.handleLinkClick(headerLink.link) }
                type='button'
              >
                { headerLink.copy }
              </button>
            </li>
          );
        }) }
        <li className={ styles.linkItem }>
          <button
            className={ cx(styles.exploreFrameLink, styles.link) }
            onClick={ () => this.handleExternalLinkClick(LINKS.FRAME_IO) }
            type='button'
          >
            Explore Frame.io
            <Icon className={ styles.exploreFrameIcon } icon={ ICONS.EXTERNAL_LINK } />
          </button>
        </li>
      </ul>
    );
  }

  handleLinkClick = link => {
    const clickInfo = {
      client: 'wfg',
      page: window.location.pathname,
      position: 'top',
      title: `header - ${ link }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    navigate(link);
  }

  handleExternalLinkClick = link => {
    const clickInfo = {
      client: 'wfg',
      page: window.location.pathname,
      position: 'top',
      title: `header - ${ link }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    window.open(link, '_blank');
  }
}

export default HamburgerMenu;
