import React from 'react';

import InlineCTA from './InlineCTA';

import ContextConsumer from '../../../context/ContextConsumer';

const InlineCTAWrapper = props => (
  <ContextConsumer>
    { context => (
      <InlineCTA
        { ...props }
        { ...context.state }
      />
    ) }
  </ContextConsumer>
);

export default InlineCTAWrapper;
