import React from 'react';

import ThemeToggle from './ThemeToggle';

import ContextConsumer from '../../context/ContextConsumer';

const ThemeToggleWrapper = props => (
  <ContextConsumer>
    { context => (
      <ThemeToggle
        { ...props }
        { ...context.state }
        { ...context.actions }
      />
    ) }
  </ContextConsumer>
);

export default ThemeToggleWrapper;
