import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Context from '../../context/Context';

import { getContentComponentByType } from '../../utils/content-helpers';

import styles from './index.module.css';

const ContentList = ({
  className,
  content,
  isInSubtopic,
  slug,
  typeClassNames,
}) => {
  const { state } = useContext(Context);

  const wrapperClasses = cx({
    [styles.wrapper]: true,
    [styles.themeDark]: state.theme === 'dark',
  }, className);

  return (
    <div className={ wrapperClasses }>
      { content.map((contentItem, i) => {
        const ContentComponent = getContentComponentByType(contentItem.content_type);
        const key = `${ slug }-${ i }`;

        if (!ContentComponent) return null;

        return (
          <ContentComponent
            className={ typeClassNames[contentItem.content_type] }
            isInSubtopic={ isInSubtopic }
            key={ key }
            slug={ slug }
            { ...contentItem }
          />
        );
      }) }
    </div>
  );
};

ContentList.defaultProps = {
  typeClassNames: {},
};

ContentList.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({
    content_type: PropTypes.string.isRequired, // TODO check for actual accepted types
  })).isRequired,
  isInSubtopic: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  typeClassNames: PropTypes.object,
};

export default ContentList;
