const throttle = (
  func,
  wait = 17,
) => {
  let time = Date.now();
  return () => {
    if (((time + wait) - Date.now()) < 0) {
      func();
      time = Date.now();
    }
  };
};

export default throttle;
