import VIEWPORTS from './viewports';

export const HEADER_HEIGHT = {
  [VIEWPORTS.SMALL]: 70,
  [VIEWPORTS.MEDIUM]: 70,
  [VIEWPORTS.LARGE]: 110,
  [VIEWPORTS.XLARGE]: 110,
};

export default {};
