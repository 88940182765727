import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

const VideoEmbed = ({
  className,
  content_embed,
}) => (
  <div className={ cx(styles.wrapper, className) }>
    <iframe
      className={ styles.video }
      type='text/html'
      src={ `https://www.youtube.com/embed/${ content_embed && content_embed.content_id }?rel=0&modestbranding=0&playsinline=0` }
      frameBorder='0'
    />
  </div>
);

VideoEmbed.propTypes = {
  className: PropTypes.string,
  content_embed: PropTypes.shape({
    content_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default VideoEmbed;
