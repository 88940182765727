import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Btn from '../../Btn';

import styles from './index.module.css';

class InlineCTA extends Component {
  static propTypes = {
    activeChapter: PropTypes.string,
    className: PropTypes.string,
    content_inlineCTA: PropTypes.shape({
      content_title: PropTypes.string.isRequired,
      content_description: PropTypes.string,
      content_ctaCopy: PropTypes.string.isRequired,
      content_ctaURL: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const {
      className,
      content_inlineCTA: {
        content_title,
        content_description,
        content_ctaCopy,
      },
    } = this.props;

    return (
      <div className={ cx(styles.wrapper, className) }>
        <h4 className={ styles.title }>
          { content_title }
        </h4>
        { content_description &&
          <p className={ styles.description }>
            { content_description }
          </p>
        }
        <div className={ styles.ctaWrapper }>
          <Btn
            hasFluidWidth
            label={ content_ctaCopy }
            onClick={ this.handleBtnClick }
          />
        </div>
      </div>
    );
  }

  handleBtnClick = () => {
    const {
      activeChapter,
      content_inlineCTA: {
        content_ctaURL,
        content_title,
      },
    } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ activeChapter }`,
      position: 'middle',
      title: `Inline CTA: ${ content_title }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    window.open(content_ctaURL, '_blank');
  }
}

export default InlineCTA;
