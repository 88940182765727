import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({
  className,
  icon,
}) => (
  <svg
    className={ className }
    role='img'
    viewBox={ icon.viewBox }
  >
    { icon.data }
  </svg>
);

Icon.defaultProps = {
  className: null,
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.shape({
    viewBox: PropTypes.string.isRequired,
    data: PropTypes.element.isRequired,
  }).isRequired,
};

export default Icon;
