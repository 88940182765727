import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Context from '../../context/Context';

import ICONS from '../../constants/icons';

import Icon from '../Icon';

import styles from './index.module.css';

const ThemeToggle = ({
  isInHamburger,
}) => {
  const { state, actions } = useContext(Context);

  function handleToggleBtnClick() {
    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ state.activeChapter }`,
      position: 'bottom left',
      title: `change theme: ${ state.theme === 'light' ? 'dark' : 'light' }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    actions.onChangeTheme();
  }

  const wrapperClasses = cx({
    [styles.wrapper]: true,
    [styles.isInHamburger]: isInHamburger,
    [styles.themeDark]: isInHamburger ? state.theme !== 'dark' : state.theme === 'dark',
  });

  return (
    <div className={ wrapperClasses }>
      <Icon className={ cx(styles.icon, styles.iconLight) } icon={ ICONS.THEME_LIGHT } />
      <button
        className={ styles.toggleBtn }
        type='button'
        onClick={ handleToggleBtnClick }
      >
        <div className={ styles.toggleBtnInner }>
          <div className={ styles.toggleBtnCircle } />
        </div>
      </button>
      <Icon className={ cx(styles.icon, styles.iconDark) } icon={ ICONS.THEME_DARK } />
    </div>
  );
};

ThemeToggle.propTypes = {
  isInHamburger: PropTypes.bool,
};

export default ThemeToggle;
