export const SOCIAL_TYPES = {
  EMAIL: 'EMAIL',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
};

export const GUIDE_SOCIAL = {
  [SOCIAL_TYPES.EMAIL]: {
    mailto: '',
    subject: 'Check out Frame.io’s new Workflow Guide',
    body: '100,000 words on workflows… Check out Frame.io’s new post-production Workflow Guide: ',
  },
  [SOCIAL_TYPES.FACEBOOK]: {
    text: '100,000 words on workflows… Check out Frame.io’s new post-production Workflow Guide',
  },
  [SOCIAL_TYPES.TWITTER]: {
    text: 'Check it out ⚡@Frame_io released a 100,000-word post-production workflow guide.',
  },
};
