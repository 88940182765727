import React from 'react';

import CTABlurGate from './CTABlurGate';

import ContextConsumer from '../../context/ContextConsumer';

const CTABlurGateWrapper = props => (
  <ContextConsumer>
    { context => (
      <CTABlurGate
        { ...props }
        { ...context.state }
        { ...context.actions }
      />
    ) }
  </ContextConsumer>
);

export default CTABlurGateWrapper;
