import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import cx from 'classnames';
import queryString from 'query-string';

import { scrollWindowTo } from '../../../utils/dom';

import { HEADER_HEIGHT } from '../../../constants/header';

import ClipboardBtn from '../../ClipboardBtn';
import withScrollListener from '../../withScrollListener';
import withWindowListener from '../../withWindowListener';

import styles from './index.module.css';

class SectionHeading extends Component {
  static propTypes = {
    activeChapter: PropTypes.string.isRequired,
    activeSection: PropTypes.string.isRequired,
    className: PropTypes.string,
    content_section_heading: PropTypes.shape({
      content_heading: PropTypes.string.isRequired,
      content_id: PropTypes.string.isRequired,
    }).isRequired,
    isInSubtopic: PropTypes.bool,
    isSubtopicPage: PropTypes.bool,
    onChangeActiveSection: PropTypes.func.isRequired,
    onChangeGuideActiveSectionToPrevious: PropTypes.func,
    onChangeScrollToSection: PropTypes.func.isRequired,
    scroll: PropTypes.number.isRequired,
    scrollToSection: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    viewport: PropTypes.string.isRequired,
  }

  state = {
    shareLink: '',
  }

  componentDidMount() {
    const {
      content_section_heading: {
        content_id,
      },
      slug,
    } = this.props;

    const urlParams = queryString.stringify({ ch: slug, s: content_id });
    const shareLink = `${ window.location.origin }/guide#${ urlParams }`;

    this.setState({
      shareLink,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      isInSubtopic,
      scroll,
      scrollToSection,
      content_section_heading: {
        content_id,
      },
    } = this.props;

    if (scrollToSection !== prevProps.scrollToSection &&
      scrollToSection === content_id
    ) {
      this.scrollToSectionHeading();
    }

    if (prevProps.scroll !== scroll && !isInSubtopic) {
      this.handleScroll(prevProps.scroll);
    }
  }

  render() {
    const {
      className,
      content_section_heading: {
        content_heading,
      },
      isInSubtopic,
      isSubtopicPage,
    } = this.props;
    const { shareLink } = this.state;

    const hasSharing = !isSubtopicPage && !isInSubtopic;

    return (
      <div className={ styles.sectionHeadingWrapper } ref={ r => { this.headingEl = r; } }>
        <h2
          className={ cx(styles.sectionHeading, className) }
          dangerouslySetInnerHTML={ { __html: content_heading } }
        />
        { hasSharing &&
          <ClipboardBtn
            className={ styles.shareBtn }
            label='Share'
            shareLink={ shareLink }
          />
        }
      </div>
    );
  }

  scrollToSectionHeading = () => {
    const { scroll, viewport } = this.props;

    const viewportOffsetTop = this.headingEl.getBoundingClientRect().top;
    const top = viewportOffsetTop + scroll - HEADER_HEIGHT[viewport];

    scrollWindowTo({
      top,
      duration: 500,
    }, this.handleScrollToSectionEnd);
  }

  handleScroll = prevScroll => {
    const {
      activeChapter,
      activeSection,
      content_section_heading: {
        content_id,
      },
      onChangeActiveSection,
      onChangeGuideActiveSectionToPrevious,
      scroll,
      slug,
    } = this.props;

    const viewportOffsetTop = this.headingEl.getBoundingClientRect().top;
    const top = viewportOffsetTop + scroll - (window.innerHeight / 2);
    const bottom = top + 300;

    // if we've scroll up 300px, we need to switch to prev section.
    // kinda gross and hacky :(
    const backwardsTop = top - 300;
    const backwardsTopEdge = backwardsTop - 300;

    if (scroll >= top &&
      scroll < bottom &&
      slug === activeChapter &&
      activeSection !== content_id) {
      onChangeActiveSection(content_id);
    } else if ((scroll < backwardsTop) &&
      (scroll > backwardsTopEdge) &&
      slug === activeChapter &&
      prevScroll > scroll &&
      onChangeGuideActiveSectionToPrevious
    ) {
      onChangeGuideActiveSectionToPrevious(content_id);
    }
  }

  handleScrollToSectionEnd = () => {
    const {
      onChangeActiveSection,
      onChangeScrollToSection,
      content_section_heading: {
        content_id,
      },
    } = this.props;

    onChangeActiveSection(content_id);
    onChangeScrollToSection('');
  }
}

export default compose(
  withScrollListener,
  withWindowListener,
)(SectionHeading);
