import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import compose from 'recompose/compose';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { getChapterFromSlug } from '../../utils/content-helpers';

import HEADER_LINKS from '../../constants/header-links';
import ICONS from '../../constants/icons';
import LINKS from '../../constants/links';

import HamburgerMenu from '../HamburgerMenu';
import Icon from '../Icon';
import withScrollListener from '../withScrollListener';
import withWindowListener from '../withWindowListener';

import styles from './index.module.css';

class Header extends Component {
  static propTypes = {
    activeChapter: PropTypes.string,
    chapters: PropTypes.array,
    hideBoxShadow: PropTypes.bool,
    isGuide: PropTypes.bool,
    isLarge: PropTypes.bool.isRequired,
    scroll: PropTypes.number.isRequired,
    theme: PropTypes.string,
  }

  state = {
    pathName: '',
    showHamburgerMenu: false,
    showLinks: true,
  }

  componentDidMount() {
    this.setState({
      pathName: window.location.pathname,
    });
  }

  componentDidUpdate(prevProps) {
    const { scroll } = this.props;

    if (prevProps.scroll !== scroll) {
      this.handleScroll(prevProps.scroll);
    }
  }

  render() {
    const {
      hideBoxShadow,
      isGuide,
      isLarge,
      theme,
    } = this.props;

    const wrapperStyles = cx({
      [styles.wrapper]: true,
      [styles.themeDark]: theme === 'dark' && isGuide,
      [styles.hideBoxShadow]: hideBoxShadow,
    });

    return (
      <div className={ wrapperStyles }>
        { isLarge ?
          this.renderDesktopHeader() : this.renderMobileHeader()
        }
      </div>
    );
  }

  renderDesktopHeader = () => (
    <div className={ styles.inner }>
      { this.renderWorkflowLogo() }
      { this.renderLinks() }
      { this.renderExploreFrame() }
    </div>
  )

  renderMobileHeader = () => {
    const { isGuide } = this.props;
    const { showHamburgerMenu } = this.state;

    return (
      <div className={ styles.inner }>
        { isGuide ? this.renderFrameLogo() : this.renderWorkflowLogo() }
        { isGuide && this.renderCurrentChapterInfo() }
        { this.renderHamburger() }
        <CSSTransition
          in={ showHamburgerMenu }
          classNames='hamburgerMenuTransition'
          timeout={ 300 }
          unmountOnExit
        >
          <HamburgerMenu
            isGuide={ isGuide }
            onCloseBtnClick={ this.handleHamburgerClose }
          />
        </CSSTransition>
      </div>
    );
  }

  renderFrameLogo = () => (
    <a
      href='/'
      onClick={ evt => this.handleLinkClick(evt, '/') }
    >
      <Icon className={ styles.frameLogo } icon={ ICONS.FRAMEIO_LOGO } />
    </a>
  )

  renderWorkflowLogo = () => (
    <div>
      <a
        href='/'
        onClick={ evt => this.handleLinkClick(evt, '/') }
      >
        <Icon className={ styles.workflowLogo } icon={ ICONS.WORKFLOW_LOGO } />
      </a>
    </div>
  )

  renderHamburger = () => (
    <button
      className={ styles.hamburgerBtn }
      type='button'
      onClick={ this.handleHamburgerOpen }
    >
      <Icon className={ styles.hamburgerIcon } icon={ ICONS.HAMBURGER } />
    </button>
  )

  renderCurrentChapterInfo = () => {
    const {
      activeChapter,
      chapters,
    } = this.props;

    const currentChapter = getChapterFromSlug(chapters, activeChapter);

    if (!currentChapter) {
      return null;
    }

    return (
      <div className={ styles.currentChapterInfo }>
        <h5
          className={ styles.currentChapterTitle }
          dangerouslySetInnerHTML={ { __html: currentChapter.node.title } }
        />
        <p
          className={ styles.currentChapterReadTime }
          dangerouslySetInnerHTML={ { __html: currentChapter.node.chapter_meta.chapter_read_time } }
        />
      </div>
    );
  }

  renderLinks = () => {
    const {
      pathName,
      showLinks,
    } = this.state;

    const linkListStyles = cx({
      [styles.linkList]: true,
      [styles.visible]: showLinks,
    });

    return (
      <ul className={ linkListStyles }>
        { HEADER_LINKS.map(headerLink => {
          const linkClasses = cx({
            [styles.link]: true,
            [styles.active]: pathName === headerLink.link,
          });

          return (
            <li className={ styles.linkItem } key={ headerLink.link }>
              <a
                className={ linkClasses }
                href={ headerLink.link }
                onClick={ evt => this.handleLinkClick(evt, headerLink.link) }
              >
                { headerLink.copy }
              </a>
            </li>
          );
        }) }
      </ul>
    );
  }

  renderExploreFrame = () => (
    <div className={ styles.exploreFrame }>
      <a
        className={ cx(styles.exploreFrameLink, styles.link) }
        href={ LINKS.FRAME_IO }
        onClick={ evt => this.handleExternalLinkClick(evt, LINKS.FRAME_IO) }
      >
        Explore Frame.io
        <Icon className={ styles.exploreFrameIcon } icon={ ICONS.EXTERNAL_LINK } />
      </a>
    </div>
  )

  handleScroll = prevScroll => {
    const { scroll } = this.props;
    const { showLinks } = this.state;

    const isScrollingDown = prevScroll < scroll;

    if (isScrollingDown && showLinks) {
      this.setState({
        showLinks: false,
      });
    } else if (!isScrollingDown && !showLinks) {
      this.setState({
        showLinks: true,
      });
    }
  }

  handleHamburgerOpen = () => {
    const { activeChapter } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ activeChapter }`,
      position: 'top',
      title: 'open hamburger menu',
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    this.setState({
      showHamburgerMenu: true,
    });
  }

  handleHamburgerClose = () => {
    const { activeChapter } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ activeChapter }`,
      position: 'top',
      title: 'close hamburger menu',
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    this.setState({
      showHamburgerMenu: false,
    });
  }

  handleLinkClick = (evt, link) => {
    evt.preventDefault();

    const clickInfo = {
      client: 'wfg',
      page: window.location.pathname,
      position: 'top',
      title: `header - ${ link }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    navigate(link);
  }

  handleExternalLinkClick = (evt, link) => {
    evt.preventDefault();

    const clickInfo = {
      client: 'wfg',
      page: window.location.pathname,
      position: 'top',
      title: `header - ${ link }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    window.open(link, '_blank');
  }
}


export default compose(
  withScrollListener,
  withWindowListener,
)(Header);
