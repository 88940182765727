import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ICONS from '../../constants/icons';
import LS_KEYS from '../../constants/local-storage';

import EmailInput from '../EmailInput';
import Icon from '../Icon';
import ShareBtns from '../ShareBtns';

import styles from './index.module.css';

const CTA_PREVIOUS_CUSTOMER = 'We’re not going to ask you share this… but it’d be nice if you did 🙏';
const CTA_NON_CUSTOMER = 'Unlock all 100,000 words of the Frame.io Workflow Guide and learn how the pros do workflow.';

class CTABlurGate extends Component {
  static propTypes = {
    chapter: PropTypes.string.isRequired,
    className: PropTypes.string,
    hasChapterCTA: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
    isPreviousCustomer: PropTypes.bool.isRequired,
    onChangeContentGated: PropTypes.func.isRequired,
    subtopic: PropTypes.string,
    theme: PropTypes.string.isRequired,
  }

  state = {
    hasSentInitialTracking: false,
  }

  componentDidMount() {
    const { isActive } = this.props;

    if (isActive) {
      this.sendInitialTracking();
    }
  }

  componentDidUpdate(prevProps) {
    const { isActive } = this.props;
    const { hasSentInitialTracking } = this.state;

    if (isActive && !prevProps.isActive && !hasSentInitialTracking) {
      this.sendInitialTracking();
    }
  }

  render() {
    const {
      className,
      isPreviousCustomer,
      theme,
    } = this.props;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.shareWrapper]: isPreviousCustomer,
      [styles.themeDark]: theme === 'dark',
    }, className);

    return (
      <div className={ wrapperClasses } id='cta-blur'>
        <div className={ styles.inner }>
          { isPreviousCustomer && this.renderCloseBtn() }
          { !isPreviousCustomer && this.renderTitle() }

          <p className={ styles.description } id='cta-blur-description'>
            { isPreviousCustomer ? CTA_PREVIOUS_CUSTOMER : CTA_NON_CUSTOMER }
          </p>

          { isPreviousCustomer ?
            this.renderSharing() : <EmailInput submitBtnLabel='Unlock' /> }
        </div>
      </div>
    );
  }

  renderCloseBtn = () => (
    <button
      className={ styles.closeBtn }
      type='button'
      onClick={ this.handleKnownUserRemoveGate }
    >
      <Icon className={ styles.closeBtnIcon } icon={ ICONS.CLOSE_BTN } />
    </button>
  )

  renderTitle = () => (
    <h3 className={ styles.title }>
      <span id='cta-blur-title-text'>
        Continue reading
      </span>
      <span className={ styles.titleGradient } id='cta-blur-title-gradient'>
        for free
      </span>
    </h3>
  )

  renderSharing = () => (
    <div className={ styles.sharingWrapper }>
      <ShareBtns
        className={ styles.shareBtns }
        transparentWhite
        onShareTrack={ this.handleShareTrack }
      />
      <button
        className={ styles.continueBtn }
        onClick={ this.handleKnownUserRemoveGate }
        type='button'
      >
        <div className={ styles.continueBtnInner }>
          Continue Reading
        </div>
      </button>
    </div>
  );

  handleKnownUserRemoveGate = () => {
    const {
      chapter,
      onChangeContentGated,
    } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ chapter }`,
      position: 'middle',
      title: 'remove blur gate (known user)',
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    onChangeContentGated(false);
    window.localStorage.setItem([LS_KEYS.WFG_KNOWN_USER], true);
  }

  handleShareTrack = type => {
    const { chapter } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ chapter }`,
      position: 'middle',
      title: `share: ${ type }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);
  }

  sendInitialTracking() {
    const {
      chapter,
      hasChapterCTA,
      isPreviousCustomer,
      subtopic,
    } = this.props;

    window.analytics.track('wfg-modal-shown', {
      version: isPreviousCustomer ? 'share' : 'subscribe',
      position: hasChapterCTA ? 'chapter' : 'subtopic',
      chapter,
      subtopic,
    });

    this.setState({
      hasSentInitialTracking: true,
    });
  }
}

export default CTABlurGate;
