import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

const Paragraph = ({
  className,
  content_p,
}) => (
  <p
    className={ cx(styles.paragraph, className) }
    dangerouslySetInnerHTML={ { __html: content_p } }
  />
);

Paragraph.propTypes = {
  className: PropTypes.string,
  content_p: PropTypes.string.isRequired,
};

export default Paragraph;
