import React, { Component } from 'react';
import throttle from '../../utils/throttle';
import viewports from '../../constants/viewports';

/* These constraints must match the values in styles/breakpoints.css */
const breakpoints = [
  {
    viewport: viewports.SMALL,
    minWidth: 0,
  },
  {
    viewport: viewports.MEDIUM,
    minWidth: 680,
  },
  {
    viewport: viewports.LARGE,
    minWidth: 1160,
  },
  {
    viewport: viewports.XLARGE,
    minWidth: 1780,
  },
];

const getViewport = width => {
  let i = breakpoints.length - 1;
  while (i >= 0 && width < breakpoints[i].minWidth) {
    i -= 1;
  }
  return breakpoints[i].viewport;
};

function withWindowListener(WrappedComponent) {
  class WindowListener extends Component {
    state = {
      viewport: viewports.SMALL,
      dimensions: {
        x: 0,
        y: 0,
      },
    }

    componentDidMount() {
      if (window.innerWidth) {
        this.setState({
          viewport: getViewport(window.innerWidth),
          dimensions: {
            x: window.innerWidth,
            y: window.innerHeight,
          },
        });
      }

      window.addEventListener('resize', this.resized);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resized);
    }

    render() {
      const { dimensions, viewport } = this.state;

      return (
        <WrappedComponent
          { ...this.props }
          dimensions={ dimensions }
          viewport={ viewport }
          isLarge={ viewport === viewports.LARGE || viewport === viewports.XLARGE }
        />
      );
    }

    resized = throttle(() => {
      this.setState({
        viewport: getViewport(window.innerWidth),
        dimensions: {
          x: window.innerWidth,
          y: window.innerHeight,
        },
      });
    }, 20);
  }

  return WindowListener;
}

export default withWindowListener;
