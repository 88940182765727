import React from 'react';

import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';

import ContextConsumer from '../../context/ContextConsumer';

const HeaderWrapper = props => (
  <StaticQuery
    query={ graphql`
      query Header {
        allWordpressPage(
          sort:{ fields: menu_order }
        ) {
          edges {
            node {
              chapter_meta {
                chapter_read_time
              }
              slug
              title
            }
          }
        }
      }
    ` }
    render={ data => (
      <ContextConsumer>
        { context => (
          <Header
            { ...props }
            { ...context.state }
            chapters={ data.allWordpressPage.edges }
          />
        ) }
      </ContextConsumer>
    ) }
  />
);

export default HeaderWrapper;
