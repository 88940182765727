import React from 'react';

import UnorderedList from './UnorderedList';

import ContextConsumer from '../../../context/ContextConsumer';

const UnorderedListWrapper = props => (
  <ContextConsumer>
    { context => (
      <UnorderedList
        { ...props }
        { ...context.state }
      />
    ) }
  </ContextConsumer>
);

export default UnorderedListWrapper;
