const CONTENT_TYPES = {
  INLINE_CTA: 'inlineCTA',
  IMAGE: 'image',
  ORDERED_LIST: 'orderedList',
  PARAGRAPH: 'paragraph',
  SECONDARY_HEADING: 'secondaryHeading',
  SECTION_HEADING: 'sectionHeading',
  SUB_TOPIC: 'subTopic',
  TERTIARY_HEADING: 'tertiaryHeading',
  UNORDERED_LIST: 'unorderedList',
  VIDEO_EMBED: 'embed',
};

export default CONTENT_TYPES;
