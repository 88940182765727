import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { getImageDataFromFiles } from '../../../utils/content-helpers';

import Image from './Image';

const ImageWrapper = props => (
  <StaticQuery
    query={ graphql`
      query Image {
        allWordpressWpMedia {
          edges {
            node{
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              caption
              alt_text
            }
          }
        }
      }
    ` }
    render={ data => (
      <Image
        { ...props }
        imgData={ getImageDataFromFiles(data, props.content_image) }
      />
    ) }
  />
);

export default ImageWrapper;
