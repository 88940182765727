import { SOCIAL_TYPES } from '../constants/social';

const socialDimensions = {
  FACEBOOK: {
    w: 600,
    h: 640,
  },
  TWITTER: {
    w: 600,
    h: 254,
  },
  EMAIL: {},
};

const openSocialLink = (url, type) => {
  if (type === SOCIAL_TYPES.EMAIL) {
    window.location.href = url;
    return;
  }

  const dimensions = socialDimensions[type] || { w: 640, h: 480 };
  const width = dimensions.w;
  const height = dimensions.h;


  const dualScreenLeft = window.screenLeft;
  const dualScreenTop = window.screenTop;

  const left = ((window.innerWidth / 2) - (width / 2)) + dualScreenLeft;
  const top = ((window.innerHeight / 2) - (height / 2)) + dualScreenTop;

  window.open(
    url,
    'Share',
    `resizable,height=${ height }px,width=${ width }px,top=${ top }px,left=${ left }px`,
  );
};

export default openSocialLink;
