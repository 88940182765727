import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import openSocialLink from '../../utils/open-social-link';

import ICONS from '../../constants/icons';
import LINKS from '../../constants/links';
import { GUIDE_SOCIAL, SOCIAL_TYPES } from '../../constants/social';

import Icon from '../Icon';

import styles from './index.module.css';

class ShareBtns extends Component {
  static propTypes = {
    activeChapter: PropTypes.string.isRequired,
    onShareTrack: PropTypes.func,
    className: PropTypes.string,
    theme: PropTypes.string.isRequired,
    transparentWhite: PropTypes.bool,
  }

  render() {
    const {
      className,
      theme,
      transparentWhite,
    } = this.props;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.themeDark]: theme === 'dark',
      [styles.transparentWhite]: transparentWhite,
    }, className);

    return (
      <div className={ wrapperClasses }>
        <ul className={ styles.shareBtnList }>
          <li className={ styles.shareBtnItem }>
            { this.renderSharingBtn(SOCIAL_TYPES.EMAIL) }
          </li>
          <li className={ styles.shareBtnItem }>
            { this.renderSharingBtn(SOCIAL_TYPES.FACEBOOK) }
          </li>
          <li className={ styles.shareBtnItem }>
            { this.renderSharingBtn(SOCIAL_TYPES.TWITTER) }
          </li>
        </ul>
      </div>
    );
  }

  renderSharingBtn = type => (
    <button
      className={ styles.shareBtn }
      type='button'
      onClick={ () => this.handleBtnClick(type) }
    >
      <div className={ styles.shareBtnInner }>
        <Icon className={ styles.shareBtnIcon } icon={ ICONS[type] } />
      </div>
    </button>
  )

  handleBtnClick = type => {
    const {
      activeChapter,
      onShareTrack,
    } = this.props;

    if (onShareTrack) {
      onShareTrack(type);
    } else {
      const clickInfo = {
        client: 'wfg',
        page: `chapter: ${ activeChapter }`,
        position: 'bottom right',
        title: `share: ${ type }`,
      };

      window.analytics.track('button-clicked', clickInfo);
      window.analytics.track('wfg-button-clicked', clickInfo);
    }

    openSocialLink(
      LINKS.SOCIAL[type]({
        ...GUIDE_SOCIAL[type],
        url: window.location.href,
      }),
      type,
    );
  }
}

export default ShareBtns;
