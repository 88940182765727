import React from 'react';

import EmailInput from './EmailInput';

import ContextConsumer from '../../context/ContextConsumer';

const EmailInputWrapper = props => (
  <ContextConsumer>
    { context => (
      <EmailInput
        { ...props }
        { ...context.state }
        { ...context.actions }
      />
    ) }
  </ContextConsumer>
);

export default EmailInputWrapper;
