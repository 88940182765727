const HEADER_LINKS = [
  {
    link: '/chapters',
    copy: 'Chapters',
  },
  {
    link: '/contributors',
    copy: 'Contributors',
  },
  {
    link: '/codec-comparison',
    copy: 'Codec Comparison',
  },
  {
    link: '/flowchart',
    copy: 'Flowchart',
  },
];

export default HEADER_LINKS;
