import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

const UnorderedList = ({
  className,
  theme,
  content_list,
}) => {
  const listClasses = cx({
    [styles.list]: true,
    [styles.themeDark]: theme === 'dark',
  }, className);

  return (
    <ul className={ listClasses }>
      { content_list.map(item => (
        <li className={ styles.item } key={ item }>
          <div className={ styles.bullet } />
          <div dangerouslySetInnerHTML={ { __html: item } } />
        </li>
      )) }
    </ul>
  );
};

UnorderedList.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string.isRequired,
  content_list: PropTypes.arrayOf(PropTypes.string),
};

export default UnorderedList;
