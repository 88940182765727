import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

const SecondaryHeading = ({
  className,
  content_section_heading: {
    content_heading,
  },
}) => (
  <h3
    className={ cx(styles.secondaryHeading, className) }
    dangerouslySetInnerHTML={ { __html: content_heading } }
  />
);

SecondaryHeading.propTypes = {
  className: PropTypes.string,
  content_section_heading: PropTypes.shape({
    content_heading: PropTypes.string.isRequired,
  }).isRequired,
};

export default SecondaryHeading;
