import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';
import cx from 'classnames';

import ICONS from '../../constants/icons';

import Icon from '../Icon';

import styles from './index.module.css';

class ClipboardBtn extends Component {
  static propTypes = {
    className: PropTypes.string,
    filled: PropTypes.bool,
    label: PropTypes.string,
    shareLink: PropTypes.string.isRequired,
    small: PropTypes.bool,
    successText: PropTypes.string,
  }

  static defaultProps = {
    label: 'Copy link',
    successText: 'Link copied!',
  }

  state = {
    isCopySuccess: false,
    copyTimeout: null,
  }

  componentDidMount() {
    this.clipboard = new Clipboard(this.btnEl);
    this.clipboard.on('success', this.handleClipboardSuccess);
  }

  render() {
    const {
      className,
      filled,
      label,
      shareLink,
      small,
      successText,
    } = this.props;
    const { isCopySuccess } = this.state;

    const btnClasses = cx({
      [styles.btn]: true,
      [styles.filled]: filled,
      [styles.small]: small,
      [styles.success]: isCopySuccess,
    }, className);

    return (
      <button
        className={ btnClasses }
        ref={ r => { this.btnEl = r; } }
        data-clipboard-text={ shareLink }
        type='button'
      >
        <div className={ styles.btnInnerDefault }>
          <Icon
            className={ styles.icon }
            icon={ isCopySuccess ? ICONS.CHECKMARK_SMALL : ICONS.SHARE }
          />
          { isCopySuccess ? successText : label }
        </div>
      </button>
    );
  }

  handleClipboardSuccess = () => {
    const { copyTimeout } = this.state;

    if (copyTimeout) {
      window.clearTimeout(copyTimeout);
    }

    const timeout = window.setTimeout(() => {
      this.setState({
        isCopySuccess: false,
      });
    }, 3000);

    this.setState({
      copyTimeout: timeout,
      isCopySuccess: true,
    });
  }
}

export default ClipboardBtn;
