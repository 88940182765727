export const scrollWindowTo = ({ top, duration = 0 }, callback = {}) => {
  if (duration === 0) {
    window.scrollTo(0, top);
    return;
  }

  const initOffset = window.pageYOffset || document.documentElement.scrollTop;
  const diff = top - initOffset;
  const fps = 1000 / 60;
  const beginTime = Date.now();

  const timerID = setInterval(() => {
    const currentTime = Date.now();
    const timeDiff = currentTime - beginTime;

    if (timeDiff < duration) {
      let t = (timeDiff / duration);
      const pct = (--t) * t * t + 1; // eslint-disable-line
      const scrollPos = initOffset + (diff * pct);
      window.scrollTo(0, scrollPos);
    } else {
      window.scrollTo(0, top);
      clearInterval(timerID);

      callback();
    }
  }, fps);
};

export default {};
