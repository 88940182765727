import React from 'react';

import SectionHeading from './SectionHeading';

import ContextConsumer from '../../../context/ContextConsumer';
import { GuideConsumer } from '../../GuideContext';

const SectionHeadingWrapper = props => (
  <ContextConsumer>
    { context => (
      <GuideConsumer>
        { guideContext => (
          <SectionHeading
            { ...props }
            { ...context.actions }
            { ...context.state }
            { ...guideContext.actions }
          />
        ) }
      </GuideConsumer>
    ) }
  </ContextConsumer>
);

export default SectionHeadingWrapper;
