import CONTENT_TYPES from '../constants/content-types';

import {
  InlineCTA,
  Image,
  OrderedList,
  Paragraph,
  SecondaryHeading,
  SectionHeading,
  SubTopic,
  TertiaryHeading,
  UnorderedList,
  VideoEmbed,
} from '../components/ContentTypes';

export const getContentComponentByType = type => {
  switch (type) {
    case CONTENT_TYPES.INLINE_CTA:
      return InlineCTA;

    case CONTENT_TYPES.IMAGE:
      return Image;

    case CONTENT_TYPES.ORDERED_LIST:
      return OrderedList;

    case CONTENT_TYPES.PARAGRAPH:
      return Paragraph;

    case CONTENT_TYPES.SECONDARY_HEADING:
      return SecondaryHeading;

    case CONTENT_TYPES.SECTION_HEADING:
      return SectionHeading;

    case CONTENT_TYPES.SUB_TOPIC:
      return SubTopic;

    case CONTENT_TYPES.TERTIARY_HEADING:
      return TertiaryHeading;

    case CONTENT_TYPES.UNORDERED_LIST:
      return UnorderedList;

    case CONTENT_TYPES.VIDEO_EMBED:
      return VideoEmbed;

    default:
      return null;
  }
};

export const getPrimaryNavFromData = data => data.allWordpressPage.edges.map(edge => ({
  slug: edge.node.slug,
  title: edge.node.chapter_meta.chapter_nav_title,
}));

export const getSecondaryNavFromData = data => {
  const chapterObj = data.allWordpressPage.edges.reduce((result, item) => {
    const key = item.node.slug;
    const newResult = result;
    newResult[key] = item.node.chapter_meta.chapter_content.filter(contentItem =>
      contentItem.content_type === CONTENT_TYPES.SECTION_HEADING).map(heading => ({
      id: heading.content_section_heading.content_id,
      title: heading.content_section_heading.content_nav_heading,
    }));

    return newResult;
  }, {});

  return chapterObj;
};

export const getFirstSectionFromChapter = chapter => {
  const sections = chapter.node.chapter_meta.chapter_content.filter(contentItem =>
    contentItem.content_type === CONTENT_TYPES.SECTION_HEADING);

  return sections.length > 0 ? sections[0].content_section_heading.content_id : '';
};

export const getPreviousSectionFromChapter = (chapter, activeSection) => {
  const sections = chapter.node.chapter_meta.chapter_content.filter(contentItem =>
    contentItem.content_type === CONTENT_TYPES.SECTION_HEADING);

  if (sections.length > 0) {
    const currentIndex = sections.findIndex(section =>
      section.content_section_heading.content_id === activeSection);

    const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;

    return sections[newIndex].content_section_heading.content_id;
  }

  return '';
};

export const getChapterFromSlug = (chapters, slug) => chapters.find(chapter =>
  chapter.node.slug === slug);

export const getCompanyDataFromFiles = (files, companies) => companies.map(company => {
  const matchingImageEdge = files.allFile.edges.find(edge => edge.node.base === company.imgName);
  const imgData = matchingImageEdge && matchingImageEdge.node;

  return {
    ...company,
    imgData,
  };
});

export const getAuthorDataFromFiles = (files, authors) => authors.map(author => {
  const matchingImageEdge = files.allFile.edges.find(edge => edge.node.base === author.imgSrc);
  const imgData = matchingImageEdge && matchingImageEdge.node;

  return {
    ...author,
    imgData,
  };
});

export const getImageDataFromFiles = (files, contentImage) => {
  const splitImg = contentImage.split('src=');
  const imgSrc = splitImg[1];

  const matchingImageEdge = files.allWordpressWpMedia.edges.find(edge =>
    edge.node.source_url === imgSrc);
  const imgData = matchingImageEdge && matchingImageEdge.node;

  return {
    ...imgData,
  };
};
