const LINKS = {
  CODEC_COMPARISON: 'https://accounts.frame.io/welcome?utm_medium=web&utm_source=wfg&utm_campaign=codec_comparison',
  FLOWCHART: 'https://app.frame.io/presentations/8bfce6d5-d278-48c8-b77b-a631572951d3',
  FRAME_IO: 'https://frame.io',
  FRAME_IO_DRIBBBLE: 'https://dribbble.com/frameio',
  FRAME_IO_FACEBOOK: 'https://www.facebook.com/frameioapp',
  FRAME_IO_TWITTER: 'https://twitter.com/Frame_io',
  PRIVACY: 'https://frame.io/privacy',
  SOCIAL: {
    FACEBOOK: ({ url, text }) => `https://www.facebook.com/sharer/sharer.php?u=${ url }&quote=${ text }`,
    TWITTER: ({ url, text }) => `http://twitter.com/intent/tweet?url=${ url }&text=${ text }`,
    EMAIL: ({
      mailto,
      url,
      subject,
      body,
    }) => `mailto:${ mailto }?&subject=${ subject }&body=${ body + url }`,
  },
  TERMS: 'https://frame.io/terms',
};

export default LINKS;
