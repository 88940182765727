export const PRIMARY_META_TAGS = [
  { name: 'page-name', content: 'Video Post-Production Workflow Guide | Frame.io' },
  { property: 'og:image', content: 'https://d2vj41uy1yy43g.cloudfront.net/workflow-guide/workflow-guide-meta.jpg' },
  { property: 'og:image:type', content: 'image/jpg' },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '630' },
  { property: 'og:title', content: 'Video Post-Production Workflow Guide | Frame.io' },
  { property: 'og:site_name', content: 'Video Post-Production Workflow Guide | Frame.io' },
  { property: 'og:url', content: 'https://workflow.frame.io' },
  { property: 'og:description', content: 'The web’s most comprehensive post-production resource, written by pro filmmakers, for pro filmmakers. Always expanding, always free.' },
  { name: 'description', content: 'The web’s most comprehensive post-production resource, written by pro filmmakers, for pro filmmakers. Always expanding, always free. ' },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:description', content: 'The web’s most comprehensive post-production resource, written by pro filmmakers, for pro filmmakers. Always expanding, always free. ' },
  { name: 'twitter:title', content: 'Video Post-Production Workflow Guide | Frame.io' },
  { name: 'twitter:site', content: '@Frame_io' },
  { name: 'twitter:image', content: 'https://d2vj41uy1yy43g.cloudfront.net/workflow-guide/workflow-guide-meta.jpg' },
  { name: 'google-site-verification', content: '34_RJHK0LQoS6gY0LQyYjnRiVXCNeesFysS3y6QzerU' },
];

export const getSubtopicMetaTags = ({ title, description }) => ([
  { name: 'page-name', content: title },
  { property: 'og:image', content: 'https://d2vj41uy1yy43g.cloudfront.net/workflow-guide/workflow-guide-meta.jpg' },
  { property: 'og:image:type', content: 'image/jpg' },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '630' },
  { property: 'og:title', content: title },
  { property: 'og:site_name', content: title },
  { property: 'og:url', content: 'https://workflow.frame.io' },
  { property: 'og:description', content: description },
  { name: 'description', content: description },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:description', content: description },
  { name: 'twitter:title', content: title },
  { name: 'twitter:site', content: '@Frame_io' },
  { name: 'twitter:image', content: 'https://d2vj41uy1yy43g.cloudfront.net/workflow-guide/workflow-guide-meta.jpg' },
  { name: 'google-site-verification', content: '34_RJHK0LQoS6gY0LQyYjnRiVXCNeesFysS3y6QzerU' },
]);
