import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

const OrderedList = ({
  className,
  content_list,
}) => (
  <ol className={ cx(styles.list, className) }>
    { content_list.map((item, i) => (
      <li className={ styles.item } key={ item }>
        <div className={ styles.number }>
          { i + 1 }
        </div>
        <div dangerouslySetInnerHTML={ { __html: item } } />
      </li>
    )) }
  </ol>
);

OrderedList.propTypes = {
  className: PropTypes.string,
  content_list: PropTypes.arrayOf(PropTypes.string),
};

export default OrderedList;
