import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

const Btn = ({
  filledWhite,
  hasFluidWidth,
  href,
  isLink,
  label,
  onClick,
}) => {
  const btnClasses = cx({
    [styles.btn]: true,
    [styles.fluid]: hasFluidWidth,
    [styles.filledWhite]: filledWhite,
  });

  if (isLink) {
    return (
      <a
        className={ btnClasses }
        href={ href }
        onClick={ onClick }
      >
        <div className={ styles.btnInner }>
          { label }
        </div>
      </a>
    );
  }

  return (
    <button
      className={ btnClasses }
      onClick={ onClick }
      type='button'
    >
      <div className={ styles.btnInner }>
        { label }
      </div>
    </button>
  );
};

Btn.propTypes = {
  filledWhite: PropTypes.bool,
  hasFluidWidth: PropTypes.bool,
  href: PropTypes.string,
  isLink: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Btn;
