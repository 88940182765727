import React from 'react';
import throttle from '../../utils/throttle';

function withScrollListener(WrappedComponent) {
  class ScrollListener extends React.Component {
    state = {
      scroll: 0,
    }

    componentDidMount() {
      this.setState({
        scroll: window.pageYOffset || document.documentElement.scrollTop,
      });

      window.addEventListener('scroll', this.throttleScroll);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.throttleScroll);
    }

    render() {
      const { scroll } = this.state;

      return (
        <WrappedComponent { ...this.props } scroll={ scroll } />
      );
    }

    scrolled = () => {
      this.setState({
        scroll: window.pageYOffset || document.documentElement.scrollTop,
      });
    }

    throttleScroll = throttle(this.scrolled);
  }

  return ScrollListener;
}

export default withScrollListener;
